<script lang="ts">
  import headshot from "$lib/images/about/Andy_other.jpg"
</script>

<section>
  <h1 class="sr-only">About Me</h1>
  <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
    <div>
      <img alt="Andy Lynn Parker" class="object-cover w-full mt-6 rounded" src={headshot}/>
    </div>
    <div>
      <p>I love words. But don’t call me a wordsmith. Or wordslinger.</p>

      <p>I am a copywriter. I geek out about persuasive hooks and frameworks, customer research, and copywriting best practices. I want your emails, your web pages, your social paid ads. Even when I’m not writing, I’m writing.</p>

      <p>Former English teacher, grammar toad, wanna-be cartoon voice actor, lousy photographer, not-so-Suzy homemaker, video game dweebette, motorcyclist. Made in New York; born and raised in Denver, CO; living the dream in
        <del>the East Bay, CA,</del>
        <del>Vancouver, WA,</del>
         Denver, CO.
      </p>
    </div>
  </div>
</section>
